@import "../../layout.scss";

#verification {
    @include column(center, center);
    width: 100%;

    a {
        cursor: pointer;
    }

    #verification-explanation {
        width: calc(100% - 32px);
        text-align: center;
    }

    .verification-input-container {
        @include column(flex-start);
        margin-top: 12px;

        .label {
            padding-left: 16px;
            font-size: 12px;
            padding-bottom: 6px;
        }
    }

    .password-requirements {
        margin-top: 12px;
    }

    .verification-button-container {
        @include center;
        width: 100%;
        margin-top: 32px;
    }

    .verification-bottom {
        margin-top: 16px;
    }

    .password-requirements {
        width: calc(100% - 32px);

        & > div {
            @media only screen and (max-width: 385px) {
                font-size: 13px;
            }

            @media only screen and (max-width: 360px) {
                font-size: 12px;
            }

            @media only screen and (max-width: 340px) {
                font-size: 11px;
            }
        }
    }
}