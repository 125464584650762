@import "../../layout";

#event {
    @include column(center, center);
    position: relative;
    top: -32px;
    width: 100%;
    margin-bottom: 48px;

    &-back-button {
        position: absolute;
        top: 41px;
        left: 28px;
        z-index: 4;

        a {
            @include themed() {
                color: t('color5');

                svg {
                    filter: drop-shadow(1px 1px 4px rgba(t('color4'), 0.4));
                }
            }
        }
    }

    &-share-button {
        position: absolute;
        top: 41px;
        right: 28px;
        z-index: 4;

        a {
            @include themed() {
                color: t('color5');

                svg {
                    filter: drop-shadow(1px 1px 4px rgba(t('color4'), 0.4));
                }
            }
        }
    }

    &-image-container {
        @include center;
        z-index: 3;
        width: 100%;
        height: 260px;
        overflow: hidden;
        mask-image: linear-gradient(to bottom, rgba(#000, 0.4) 0%, #000 25%, #000 48%, rgba(#000, 0) 100%);

        img {
            width: 100%;
            min-height: 260px;
            object-fit: cover;
        }
    }

    &-name {
        position: absolute;
        top: 180px;
        z-index: 4;
        width: 100%;
        font-size: 24px;
        font-weight: 600;
        text-align: center;

        top: 220px;
        text-align: left;
        margin-left: 16px;
        width: calc(100% - 16px);

        @include themed() {
            text-shadow: 2px 2px 10px t('color4');
        }
    }

    .list-tile-title {
        font-size: 16px;
        font-weight: 600;
    }

    .list-tile-subtitle {
        font-size: 12px;
    }

    .circle-icon-container {
        @include circle-avatar(48px);
        
        @include themed() {
            color: t('link-color');
            background-color: t('color3');
        }
    }

    .divider {
        width: 100%;

        hr {
            width: calc(100% - 32px);
            border-style: solid;

            @include themed() {
                border-color: t('link-color');
            }
        }
    }

    &-about {
        @include column(flex-start, center, 12px);
        width: calc(100% - 32px);
        padding: 0 16px;

        h3 {
            font-size: 16px;
            font-weight: 600;
            margin: 0px;
        }

        p {
            font-size: 13px;
            margin: 0px;
        }
    }

    &-button-container {
        @include center;
        position: fixed;
        top: auto;
        bottom: 61px;
        left: 0px;
        z-index: 4;
        width: 100%;
        height: 80px;

        button {
            box-shadow: 2px 2px 5px 1px rgba(#000, 0.4);
        }
    }
}