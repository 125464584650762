@import "../../layout";

#home {
    @include column(center, center);
    width: 100%;

    .home-title {
        @include row(center, space-between);
        width: calc(100% - 32px);
        margin: 12px 16px;

        h2 {
            font-size: 18px;
            font-weight: 600;
            margin: 0px;
        }

        a {
            @include row(center, null, 4px);
            font-size: 13px;
        }
    }

    #home-filter-fixed {
        @include row(center, space-between);
        width: calc(100% - 32px);
        margin-bottom: 12px;
    }

    #home-filter-variable {
        @include row(center, null, 12px);
        width: calc(100% - 32px);
        min-height: 32px;
        margin-bottom: 12px;

        .home-filter-item {
            @include row(center, null, 8px);
            padding: 4px;
            height: 24px;
            border-radius: 0.5em;

            @include themed() {
                background-color: rgba(t('color3'), 0.8);
            }

            .home-filter-image {
                @include avatar(24px);
            }

            .home-filter-content {
                font-size: 12px;
                font-weight: 600;
            }

            .home-filter-remove-button-container {

                button {
                    @include center;
                    font-size: 10px;
                    padding: 0px;
                    margin: 0px 8px 0px 0px;

                    @include themed() {
                        color: t('color5');
                    }
                }
            }
        }
    }

    #home-grid {
        display: grid;
        align-items: flex-start;
        justify-items: center;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        width: calc(100% - 32px);
        padding: 0px 16px;

        .home-pass-container {
            @include column(center, center);
            width: 100%;

            & > .wallet-pass-portrait {
                @include themed() {
                    background-color: rgba(mix(t('color3'), rgba(t('link-color'), 0.2)), 0.8);
                }
            }

            &-transfer > .wallet-pass-portrait {
                @include themed() {
                    background-color: rgba(t('color3'), 0.8);
                }
            }

            .pass-buttons-container {
                @include row(center, space-evenly);
                width: 100%;
                margin-top: -10px;
                z-index: 2;

                button {
                    @include center;
                    $size: 32px;
                    width: $size;
                    height: $size;
                    border-radius: $size / 2;
                    padding: 0px;
                    box-shadow: 0px 0px 8px 1px rgba(#000, 0.15);
                    backdrop-filter: blur(5px);

                    @include themed() {
                        background-color: mix(rgba(t('color3'), 0.6), rgba($primary, 0.2));
                    }
                }
            }
        }
    }

    #home-timeline {
        width: calc(100% - 32px);
    }

    .home-prompt {
        @include column(center, center);
        width: calc(100% - 32px);

        img {
            width: 55vw;
        }

        h2 {
            margin-top: 40px;
            margin-bottom: 0px;
        }

        p {
            text-align: center;
            font-size: 14px;
            margin-top: 12px;
            margin-bottom: 40px;
        }
    }

    .pass-loader-progressbar {
        position: relative;
        width: 18px;
        height: 18px;

        .pass-loader-progressbar-circle {
            position: absolute;
            right: 0px;
            width: 100%;
            height: 100%;
            border: solid 3px transparent;
            border-top-color:  $primary;
            border-right-color:  $primary;
            border-radius: 50%;
            transform: rotate(135deg);  
            animation: spin 0.6s infinite;
        }
    }
}