@import "../../layout";

.binary-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0px;
        height: 0px;

        &:checked + .binary-switch-slider {
            background-color: $primary;
        }

        &:focus + .binary-switch-slider {
            box-shadow: 0px 0px 1px $primary;
        }

        &:checked + .binary-switch-slider:before {
            transform: translateX(26px);
        }
    }

    &-slider {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: 0.4s;

        @include themed() {
            background-color: t('disabled-color');
        }

        &:before {
            content: "";
            position: absolute;
            top: 4px;
            left: 4px;
            height: 26px;
            width: 26px;
            transition: 0.4s;

            @include themed() {
                background-color: t('color3');
            }
        }

        &.rounded {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }
    }
}