@import "../../layout";

$transition-curve: cubic-bezier(0, 1.5, 0.6, 0.85);
$colors: (
    blue: #1652c0,
    red: #c02222,
    green: #16c052,
    yellow: #a0a016,
    orange: #c08216,
    gray: #888
);


@mixin bg-gradient($color) {
    @include themed() {        
        background: linear-gradient(-45deg, rgba(t('color2'), 0.15), rgba(t('color4'), 0.4)), $color !important;
    }
}

@mixin bg-solid($color) {
    background-color: rgba($color, 0.4) !important;
}

#pass {
    @include column(center, center, 16px);
    width: 100%;
    min-height: calc(100vh - 264px);

    .pass-prompt {
        @include column(center, center);
        width: calc(100% - 32px);

        img {
            width: 55vw;
        }

        h2 {
            margin-top: 40px;
            margin-bottom: 0px;
        }

        p {
            text-align: center;
            font-size: 14px;
            margin-top: 12px;
            margin-bottom: 40px;
        }
    }

    #pass-disclaimer {
        @include center;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 4;
        width: 100%;
        height: 100vh;
        backdrop-filter: blur(5px);

        @include themed() {
            background-color: rgba(t('color5'), 0.15);
        }

        #pass-disclaimer-container {
            @include column(center, center, 16px);
            max-width: 400px;
            width: 60vw;
            padding: 24px;
            border-radius: 0.5em;

            @include themed() {
                background-color: t('color3');
            }

            @media only screen and (max-width: 480px) {
                margin-bottom: 60px;
            }

            #pass-disclaimer-title {
                font-weight: bold;
                font-size: 13px;
            }

            #pass-disclaimer-text {
                text-align: center;
                font-size: 11px;
            }

            #pass-disclaimer-dont-show-again .form-input-checkbox {
                font-size: 13px;
            }
        }
    }

    #qr-code-color-selector {
        @include row(center, center, 8px);
        width: 100%;

        .qr-code-color-circle {
            @include center;
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            border-radius: 12px;

            @include themed() {
                border: 1px solid rgba(t('color5'), 0.2);
            }

            &.selected {
                @include themed() {
                    border-color: t('color5');
                }
            }

            .qr-code-color {
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
                border-radius: 10px;
            }
        }
    }

    #qr-code-holder {
        @include column(center, center, 16px);

        #qr-code {
            @include center;
            position: relative;
            border-radius: 8px;
            padding: 0px;
            padding-bottom: 32px;
            width: 286px;
            height: 286px;
            overflow: hidden;

            &.bg-event {
                svg {
                    opacity: 0.85;

                    @include themed() {
                        background-color: rgba(t('color4'), var(--bg-opacity));
                    }
                }
            }

            #qr-code-bg-overlay {
                @include row(flex-end, center);
                position: absolute;
                z-index: 2;
                width: 286px;
                height: 316px;
                border-radius: 8px;
                backdrop-filter: blur(2px);
                transition: background 2.5s $transition-curve;

                @include themed() {
                    background: linear-gradient(-45deg, rgba(t('color2'), 0.15), rgba(t('color4'), 0.4)), rgba($primary, 0.4);
                }
    
                &.alternate {
                    @include themed() {
                        background: linear-gradient(-45deg, rgba(t('color2'), 0.15), rgba(t('color4'), 0.4)), rgba(t('link-color'), 0.4);
                    }
                }

                #qr-code-footer {
                    @include row(center, center, 8px);
                    width: calc(100% - 64px);
                    margin-bottom: 6px;

                    hr {
                        width: 100%;
                        height: 2px;
                        border: 0px;
                        background-size: 8px 2px;
                        background-position: center;
                        background-repeat: repeat-x;

                        @include themed() {
                            background-image: linear-gradient(to right, t('color5') 15%, transparent, transparent, transparent, transparent);
                        }
                    }

                    img {
                        width: 64px;
                    }
                }
            }

            #qr-code-bg-image {
                @include avatar(286px, 0px);
                position: absolute;
                z-index: 1;
            }

            svg {
                position: realtive;
                z-index: 3;
                width: 256px;
                height: 256px;
                padding: 15px;
            }
        }

        #qr-code-progressbar-container {
            background-color: rgba($primary, 0.4);
            border-radius: 4px;
            width: 286px;
            height: 8px;
            overflow: hidden;
            transition: background-color 2.5s $transition-curve;

            &.alternate {
                @include themed() {
                    background-color: rgba(t('link-color'), 0.4);
                }
            }

            #qr-code-progressbar {
                background-color: $primary;
                border-radius: 4px;
                height: 8px;
                transition: background 2.5s $transition-curve;

                @include themed() {
                    background: linear-gradient(-45deg, rgba(t('color2'), 0.15), rgba(t('color4'), 0.4)), rgba($primary, 0.6);
                }

                &.alternate {
                    @include themed() {
                        background: linear-gradient(-45deg, rgba(t('color2'), 0.15), rgba(t('color4'), 0.4)), rgba(t('link-color'), 0.6);
                    }
                }
            }
        }
    }

    .bg-gradient-default {
        @include themed() {
            background: linear-gradient(-45deg, rgba(t('color2'), 0.15), rgba(t('color4'), 0.4)), linear-gradient(-45deg, $primary, $primary, $primary, t('link-color'), t('link-color'), t('link-color'));
        }
    }

    @each $color-name, $color in $colors {
        .bg-gradient-#{$color-name} {
            @include bg-gradient($color);
        }

        .bg-solid-#{$color-name} {
            @include bg-solid($color);
        }
    }

    #pass-info {
        @include center;
        width: calc(100% - 32px);
        padding: 0px 16px;
        font-size: 11.5px;

        @include themed() {
            color: t('link-color');
        }
    }
}