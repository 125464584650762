@import "../../layout";

#settings {
    @include column(center, center);
    width: 100%;

    #settings-picture-container {
        @include circle-avatar(80px, contain);
        margin-bottom: 12px;
    }

    #settings-user-name {
        width: calc(100% - 32px);
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    #settings-logo {
        img {
            width: 120px;
        }
    }

    .list-tile {
        padding: 2px 16px;

        .list-tile-trailing {
            @include row(center, null, 12px);
            font-size: 12px;
            white-space: nowrap;
        }
    }

    #settings-signout, #settings-signin {
        width: 100%;
    }

    #settings-signout {
        @include themed() {
            color: t('error-color');
        }
    }

    #settings-signin {
        color: $primary;
    }

    .terms {
        margin-top: 32px;
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
        font-size: 10px;
    }
}