@import "../../layout";

#review-summary {
    @include column(center, center, 16px);
    width: 100%;

    #review-summary-session {
        @include row(center, flex-start, 12px);
        width: calc(100% - 32px - 1em);
        border-radius: 0.5em;
        padding: 0.5em;

        @include themed() {
            background-color: rgba(t('color3'), 0.8);
        }

        #review-summary-session-image {
            @include avatar(80px);
        }

        #review-summary-session-info {
            @include column(flex-start, space-between);
            height: 72px;

            #review-summary-session-name {
                font-size: 16px;
                font-weight: 600;
            }

            #review-summary-session-date {
                @include row(center, flex-start, 8px);
                font-size: 13px;
            }

            #review-summary-session-location {
                @include row(center, flex-start, 8px);
                font-size: 12px;   
            }

            .icon {
                @include themed() {
                    color: t('link-color');
                }
            }
        }
    }

    #review-summary-table {
        @include column(center, center, 8px);
        width: calc(100% - 64px);
        border-radius: 0.5em;
        padding: 16px;

        @include themed() {
            background-color: rgba(t('color3'), 0.8);
        }

        .review-summary-item {
            @include row(center, space-between);
            width: 100%;
            font-size: 14px;

            .review-summary-item-description {
                white-space: nowrap;
                text-overflow: clip;
                max-width: 220px;
                width: 220px;
                mask-image: linear-gradient(to right, #000 75%, transparent);
            }
        }

        #review-summary-total {
            padding-top: 8px;
            font-weight: bold;

            @include themed() {
                border-top: 1px solid t('link-color');
            }
        }
    }

    .unsigned-title {
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
        margin-bottom: 16px;
    }

    .unsigned-form-field {
        @include column(flex-start);

        .label {
            padding-left: 16px;
            font-size: 12px;
            padding-bottom: 6px;
        }
    }

    .payment-button-container {
        @include row(center, center, 16px);
        width: calc(100% - 32px);

        &-box {
            @include column(center, center, 16px);
            font-size: 12px;
            box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
            padding: 0.8em;
            border-radius: 0.8em;
            width: calc(50vw - 24px - 1.6em);
            height: 72px;
            cursor: pointer;

            @include themed() {
                background-color: rgba(t('color3'), 0.9);
            }

            img {
                width: 36px;
            }
        }

        .list-tile {
            height: 48px;
            padding: 0px;

            .list-tile-title {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}