@import "../../layout";

.list-tile {
    @include row(center, center, 16px);
    $tile-size: var(--tile-size);
    width: calc(100% - 32px);
    height: $tile-size;
    padding: 8px 16px;

    @include themed() {
        border-bottom: 1px t('link-color') solid;
    }

    &.no-separator {
        border-bottom: 0px;
    }

    .list-tile-leading, .list-tile-main, .list-tile-trailing {
        height: $tile-size;
    }

    .list-tile-leading, .list-tile-trailing {
        @include center;
        font-size: calc(0.25 * $tile-size);
    }

    .list-tile-main {
        @include column(flex-start, center);
        width: 100%;

        .list-tile-title {
            font-size: calc(0.28125 * $tile-size);
            font-weight: 400;
        }

        .list-tile-subtitle {
            font-size: calc(0.21875 * $tile-size);
            font-weight: 400;
        }
    }
}