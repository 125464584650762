@import "../../../layout";

.expanded {
    .form-input-dropdown-content .form-input-dropdown-content-chevrons {
        opacity: 1;
    }

    .form-input-dropdown-options {
        display: block;
    }
}

.form-input-dropdown-click-out {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    opacity: 0;
    z-index: 2;
}

.form-input-dropdown {
    position: relative;

    &-content {
        @include row(center, space-between, 14px);
        position: relative;
        max-width: calc(100vw - 72px);
        width: calc(var(--select-width) - 24px);
        height: calc(var(--select-height) - 8px);
        border-radius: 0.5em;
        padding: 4px 12px;
        cursor: pointer;

        @include themed() {
            background-color: rgba(t('color3'), 0.7);
            background-image: linear-gradient(60deg, rgba(t('color2'), 0.15), rgba(t('color2'), 0));
        }

        &-selected-value {
            width: calc(var(--select-width) - var(--font-size) - 32px);
            padding: 2px 4px;
            font-size: var(--font-size);
            white-space: nowrap;
            text-overflow: clip;
            mask-image: linear-gradient(90deg, #000 75%, transparent);
    
            @include themed() {
                color: t('color5');
            }

            .placeholder {
                opacity: 0.6;
            }
        }

        &-chevrons {
            @include column(center, space-evenly);
            width: var(--font-size);
            font-size: calc(0.75 * var(--font-size));
            opacity: 0.6;
    
            @include themed() {
                color: t('color5');
            }
        }
    }

    &-options {
        position: absolute;
        top: calc(var(--select-height) + 2px);
        left: 0px;
        z-index: 3;
        display: none;
        min-width: min(calc(100vw - 52px), var(--select-width));
        border-radius: 0.5em;
        box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(5px);

        @include themed() {
            background-color: rgba(t('color3'), 0.95);
        }

        &-item {
            position: relative;
            z-index: 4;
            padding: 8px 20px;
            white-space: nowrap;
            cursor: pointer;

            &:first-child {
                border-top-left-radius: 0.5em;
                border-top-right-radius: 0.5em;
            }

            &:last-child {
                border-bottom-left-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
            }

            &:hover {
                @include themed() {
                    background-color: rgba(t('color5'), 0.2);
                }
            }
        }
    }
}