@import "../../layout";

#notifications-settings {
    @include column(center, center, 12px);

    .notifications-settings-prompt {
        @include column(center, center);
        width: calc(100% - 32px);

        h2 {
            @include row(center, flex-start, 12px);
            width: 100%;
            font-size: 20px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        p {
            width: 100%;
            font-size: 13px;
            margin: 12px auto 0px auto;
        }
    }

    h3 {
        @include row(center, null, 8px);
        font-size: 16px;
        width: calc(100% - 32px);
        margin: 24px auto 12px auto;
    }

    .notifications-settings-group {
        width: 100%;
        height: auto; // change to 0px
        overflow: hidden;
        transition: all 0.2s ease-out;

        &.open {
            height: 56px * 5;
        }
    }

    .list-tile {
        padding: 2px 16px;
    }
}