@import "../../layout";

#payment {
    @include column(center, center, 16px);
    width: 100%;

    &-deadline {
        @include column(center, null, 8px);
        font-size: 12px;
        text-align: center;
        width: calc(100% - 32px);
    }

    &-form {
        @include column(center, center, 12px);
        width: 100%;

        .payment-form-field {
            @include column(flex-start);

            .label {
                padding-left: 16px;
                font-size: 12px;
                padding-bottom: 6px;
            }

            .form-input-dropdown-content {
                width: calc(100vw - 36px);
                border-radius: 16px;
            }

            .form-input-dropdown-options {
                z-index: 10;
                border-radius: 16px;
            }
        }

        .payment-form-row {
            @include row(center, center, 24px);

            @media only screen and (min-width: 600px) {
                .form-input-text {
                    width: 158px;
                }
            }

            @media only screen and (max-width: 600px) {
                width: calc(100% - 36px);

                .form-input-text {
                    width: calc(100% - 40px);
                }
            }
        }

        .pay-button-container {
            @include center;
            margin-top: 12px;
        }

        .qr-code-explain {
            @include column(center, center, 8px);
            width: calc(100% - 32px);
            padding-left: 16px;
            padding-right: 16px;
            text-align: center;
            margin-bottom: 16px;

            .light {
                font-size: 11px;
                opacity: 0.6;
            }
        }

        .qr-code-holder {
            @include column(center, center, 16px);
            width: 100%;
    
            & > .qr-code {
                border-radius: 15px;
                padding: 15px;
                width: 256px;
                height: 256px;
                
                @include themed() {
                    background-color: rgba(t('color3'), 0.9);
                }
            }

            button {
                @include row(center, center, 8px);
                width: 286px;
            }
        }
    }
}