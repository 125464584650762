#register-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    .register-name-prompt {
        width: calc(100% - 32px);
        text-align: center;
    }
}