@import "../../layout";

#wallet-event {
    @include column(center, center);
    position: relative;
    top: -32px;
    width: 100%;
    margin-bottom: 48px;

    #wallet-event-back-button {
        position: absolute;
        top: 41px;
        left: 28px;
        z-index: 4;

        a {
            @include themed() {
                color: t('color5');
            }
        }
    }

    #wallet-event-qr-button {
        position: absolute;
        top: 41px;
        right: 28px;
        z-index: 4;

        a {
            @include themed() {
                color: t('color5');
            }
        }
    }

    #wallet-event-image-container {
        @include center;
        z-index: 3;
        width: 100%;
        height: 260px;
        overflow: hidden;
        mask-image: linear-gradient(to bottom, rgba(#000, 0.115), rgba(#000, 0.3), #000, rgba(#000, 0.3), rgba(#000, 0));

        img {
            width: 100%;
            min-height: 260px;
            object-fit: cover;
        }
    }

    #wallet-event-name {
        position: absolute;
        top: 180px;
        z-index: 4;
        width: 100%;
        font-size: 24px;
        font-weight: 600;
        text-align: center;

        @include themed() {
            text-shadow: 2px 2px 10px t('color4');
        }
    }

    .list-tile-title {
        font-size: 16px;
        font-weight: 600;
    }

    .list-tile-subtitle {
        font-size: 12px;
    }

    .circle-icon-container {
        @include circle-avatar(48px);
        
        @include themed() {
            color: t('link-color');
            background-color: t('color3');
        }
    }

    .divider {
        width: 100%;

        hr {
            width: calc(100% - 32px);
            border-style: solid;

            @include themed() {
                border-color: t('link-color');
            }
        }
    }

    #wallet-event-about {
        @include column(flex-start, center, 12px);
        width: calc(100% - 32px);
        padding: 0 16px;

        h3 {
            font-size: 16px;
            font-weight: 600;
            margin: 0px;
            margin-top: 12px;

            &:first-child {
                margin-top: 0px;
            }
        }

        p {
            font-size: 13px;
            margin: 0px 0px 12px 0px;
        }

        .wallet-tickets {
            @include column(flex-start, center, 8px);
            width: 100%;

            .wallet-tickets-session {
                width: 100%;
                font-size: 14px;

                .light {
                    opacity: 0.6;
                    font-size: 12px;
                }
            }

            .wallet-tickets-batches {
                @include row(center, space-between);
                width: 100%;
                height: 24px;
                font-size: 13px;

                .wallet-tickets-batches-used {
                    font-size: 12px;
                    opacity: 0.8;
                }
    
                button {
                    height: 24px;
                    padding: 4px 8px;
                    font-size: 12px;
                    border-width: 1px;
                }
            }
        }
    }

    #wallet-event-button-container {
        @include center;
        position: fixed;
        top: auto;
        bottom: 61px;
        left: 0px;
        z-index: 4;
        width: 100%;
        height: 80px;

        button {
            @include row(center, center, 8px);
            box-shadow: 2px 2px 5px 1px rgba(#000, 0.4);
        }
    }
}