@import "../../layout";

@mixin event-card($size, $font-size) {
    @include column(flex-start, center, 0.5em);
    width: calc($size - 1.6em);
    padding: 0.8em;
    border-radius: 0.8em;
    cursor: pointer;

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
        box-shadow: 0px 0px calc(0.065 * $size) calc(0.022 * $size) rgba(#000, 0.2);
    }

    &-image {
        @include avatar(calc($size - 1.6em), 0.8em);
    }

    &-name {
        width: calc($size - 1.6em);
        margin-top: 0.25em;
        font-size: $font-size;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: clip;
        mask-image: linear-gradient(90deg, #000 75%, transparent);
        text-align: left;
    }

    &-date {
        font-size: calc(0.8125 * $font-size);
    }

    &-location {
        font-size: calc(0.75 * $font-size);
    }

    &-date, &-location {
        @include row(center, flex-start, 8px);

        .icon {
            @include themed() {
                color: t('link-color');
            }
        }
    }
}

.event-card {
    @include event-card(var(--card-size), var(--font-size));
}