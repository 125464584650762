@import "../../layout";

#contact {
    @include column(center, center, 12px);

    .contact-prompt {
        @include column(center, center);
        width: calc(100% - 32px);

        h2 {
            @include row(center, flex-start, 12px);
            width: 100%;
            font-size: 20px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        p {
            width: 100%;
            font-size: 13px;
            margin: 12px auto;
        }
    }

    .contact-form {
        @include row(flex-end, space-between);
        width: calc(100% - 32px);
        margin-bottom: 16px;

        .contact-form-field {
            @include column(flex-start);
            width: 100%;

            .label {
                padding-left: 16px;
                font-size: 12px;
                padding-bottom: 6px;
            }

            label {
                width: 100%;

                .form-input-text {
                    transition: all 0.2s ease-out;
                    width: calc(100% - 56px);
                }
            }
        }

        .form-button {
            margin-bottom: 11px;

            button {
                transition: all 0.2s ease-out;
                padding: 8px 12px;
            }
        }
    }
}