@import "../../layout";

@mixin wallet-pass($size, $font-size, $image-size: null) {
    $padding: 0.8em;
    width: calc($size - (2 * $padding));
    padding: $padding;
    border-radius: $padding;
    box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
    cursor: pointer;

    @include themed() {
        background-color: rgba(t('color3'), 0.8);
    }

    &.used {
        filter: grayscale(0.95);
        cursor: default;

        @include themed() {
            color: rgba(t('color5'), 0.5);
        }

        .wallet-pass-event-image {
            opacity: 0.55;
        }    
    }

    .wallet-pass-event-image {
        @if $image-size != null {
            @include avatar($image-size, $padding);
        } @else {
            @include avatar(calc($size - (2 * $padding)), $padding);
        }
    }

    .wallet-pass-title, .wallet-pass-location {
        white-space: nowrap;
        text-overflow: clip;
        mask-image: linear-gradient(90deg, #000 75%, transparent);
    }

    .wallet-pass-title {
        font-size: $font-size;
        font-weight: bold;
    }

    .wallet-pass-date {
        @include row(center, flex-start, 8px);
        width: 100%;
        font-size: calc(0.875 * $font-size);
    }

    .wallet-pass-location {
        @include row(center, flex-start, 8px);
        width: 100%;
        font-size: calc(0.8125 * $font-size);
    }

    .icon {
        @include themed() {
            color: t('link-color');
        }
    }
}

@mixin wallet-pass-landscape($size, $font-size, $image-size) {
    @include wallet-pass($size, $font-size, $image-size);
    @include row(center, flex-start, 0.8em);

    .wallet-pass-details {
        @include column(flex-start, space-between);
        height: calc($image-size - 0.8em);
    }

    .wallet-pass-title {
        width: calc($size - 2.6em - $image-size);
    }
}

@mixin wallet-pass-portrait($size, $font-size) {
    @include wallet-pass($size, $font-size);
    @include column(center, center, 0.5em);

    .wallet-pass-title {
        width: calc($size - 1.6em);
        text-align: center;
    }

    .wallet-pass-quantity {
        font-weight: bold;
    }
}

.wallet-pass-landscape {
    @include wallet-pass-landscape(var(--card-size), var(--font-size), var(--image-size));
}

.wallet-pass-portrait {
    @include wallet-pass-portrait(var(--card-size), var(--font-size));
}