@import "../../layout";

#ticket-selection {
    @include column(center, center);
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);

    .code-active-prompt {
        text-align: center;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 32px;

        b {
            @include themed() {
                color: t('link-color');
            }
        }
    }

    .code-form {
        @include row(center, space-between, 12px);
        width: 160px;
        font-size: 14px;
        margin: 16px 0px;

        input {
            width: 100%;
            height: 24px;
            border-radius: 0.5em;
            padding: 4px;
            font-size: 13px;
            
            @include themed() {
                background-color: rgba(t('color3'), 0.7);
                background-image: linear-gradient(60deg, rgba(t('color2'), 0.15), rgba(t('color2'), 0));
                border: 1px solid rgba(t('color5'), 0.2);
                color: t('color5');
            }
        }

        ::-webkit-input-placeholder {
            text-align: center;
        }
          
        :-moz-placeholder {
            text-align: center;
        }

        button {
            height: 32px;
            padding: 8px 12px;
            border: 0px;
            border-radius: 0.5em;
            background-color: $primary;

            @include themed() {
                color: t('color5');
            }
        }
    }

    .segment {
        @include column(flex-start, center, 8px);
        width: 100%;
        margin-bottom: 16px;

        & > .list-tile {
            width: 100%;
            height: 32px;
            padding: 0px;

            &-title {
                font-size: 18px;
            }
        }

        .segment-batches {
            @include column(flex-start, center, 8px);
            width: 100%;

            .batch {
                width: 100%;

                & > .list-tile {
                    &-title {
                        font-size: 18px;
                    }
    
                    &-subtitle {
                        font-size: 12px;
                    }
                }

                .batch-selection-quantity {
                    @include column(flex-end, center, 8px);

                    .batch-selector {
                        @include center;

                        .batch-quantity-display {
                            width: 50px;
                            text-align: center;
                        }

                        button {
                            @include center;
                            width: 24px;
                            height: 24px;
                            min-height: auto;
                            padding: 0px;
                            border-radius: 0.5em;
                            color: $primary;
                            background-color: transparent;

                            @include themed() {
                                border: 2px solid rgba(t('color5'), 0.2);
                            }
                        }
                    }

                    .batch-subtotal {
                        white-space: nowrap;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .summary {
        @include column(center, center, 8px);
        width: calc(100% - 24px);
        margin-top: 16px;
        border-radius: 0.5em;
        padding: 12px;

        @include themed() {
            background-color: rgba(t('color3'), 0.9);
        }

        &-item {
            @include row(center, space-between, 8px);
            width: 100%;
            font-size: 14px;

            &.total {
                font-weight: bold;
                padding-top: 8px;

                @include themed() {
                    border-top: 1px solid t('link-color');
                }
            }
        }

        &-loader-progressbar {
            position: relative;
            width: 10px;
            height: 10px;
    
            &-circle {
                position: absolute;
                right: 0px;
                width: 100%;
                height: 100%;
                border: solid 2px transparent;
                border-top-color:  $primary;
                border-right-color:  $primary;
                border-radius: 50%;
                transform: rotate(135deg);  
                animation: spin 0.6s infinite;
            }
        }
    }

    .checkout-button-container {
        @include center;
        width: 100%;
        margin-top: 32px;
    }
}