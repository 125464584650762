$primary: #e404e4; // #04e49e;
$secondary: #fff;
$tertiary: #18201b;

$themes: (
    light: (
        link-color: #02895f, // green: #052 pink: #505
        disabled-color: #aaa,
        success-color: #0c4,
        info-color: #08c,
        warning-color: #c80,
        error-color: #c04,
        color1: #5a5a5a,
        color2: #ede0ed, // #e0ede9 //#e0fff0 #b0e4c4
        color3: #fff,
        color4: #e0d6e0, // #d0e0d6 //#c0ffe0
        color5: #18201b,
    ),
    dark: (
        link-color: #04e49e, // green: #afd pink: #faf
        disabled-color: #aaa,
        success-color: #0c4,
        info-color: #08c,
        warning-color: #c80,
        error-color: #c04,
        color1: #9e9e9e,
        color2: #1f2a25,
        color3: #18201b,
        color4: #000,
        color5: #fff,
    ),
);

@mixin themed() {
    @each $theme, $map in $themes {
        .#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function t($key) {
    @return map-get($theme-map, $key);
}