@import "../../layout";

#profile {
    @include column(center, center, 12px);

    #profile-picture-container {
        @include circle-avatar(160px, contain);
        margin-bottom: 12px;
    }

    .profile-form-field {
        @include column(flex-start);

        .label {
            padding-left: 16px;
            font-size: 12px;
            padding-bottom: 6px;
        }

        .form-input-dropdown-content {
            width: calc(100vw - 36px);
            border-radius: 16px;

            .form-input-dropdown-content-selected-value > svg:first-child {
                margin-right: 16px;
            }
        }

        .form-input-dropdown-options {
            z-index: 10;
            border-radius: 16px;
        }
    }

    button {
        margin-top: 16px;
    }
}