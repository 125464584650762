@import "../../layout";

#forgot-password {
    @include column(center, center, 16px);
    width: 100%;

    .forgot-password-prompt {
        @include column(center);
        width: calc(100% - 32px);
        text-align: center;

        img {
            width: 50vw;
        }

        h3 {
            margin-top: 40px;
            margin-bottom: 0px;
        }

        p {
            font-size: 14px;
            margin-top: 12px;
            margin-bottom: 0px;
        }
    }
}