@import "../../../layout";

.form-button {
    button {
        @include center;
        max-width: calc(100vw - 64px);
        font-size: 16px;
        border-radius: 0.5em;

        &.button-large {
            font-weight: 700;
            width: 380px;
            height: 58px;
            border-radius: 16px;
            padding: 18px 16px 18px 16px;
        }
    
        &.button-small {
            font-weight: 600;
            height: 38px;
            border-radius: 6px;
            padding: 8px 20px;
        }
    
        &.button-primary {
            background-color: $primary;
            border: 2px solid transparent;
    
            @include themed() {
                color: t('color5');
            }
        }
    
        &.button-secondary {
            background-color: transparent;
            color: $primary;
            border: 2px solid $primary;
        }
    
        &.button-disabled {
            @include themed() {
                &.button-primary {
                    background-color: t('disabled-color');
                    color: rgba(t('color5'), 0.8);
                }

                &.button-secondary {
                    color: t('disabled-color');
                    border-color: t('disabled-color');
                }
            }
        }

        .loading-progress {
            position: relative;
            width: 18px;
            height: 18px;

            .circle {
                position: absolute;
                right: 0px;
                width: 100%;
                height: 100%;
                border: solid 3px transparent;
                border-top-color:  $primary;
                border-right-color:  $primary;
                border-radius: 50%;
                transform: rotate(135deg);  
                animation: spin 0.6s infinite;
            }
        }
    }
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }