@import "./layout";
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap');

body {
  margin: 0;
  background-color: $tertiary;
  min-height: 100vh;
  font-family: 'Montserrat', 'Urbanist', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  div {
    transition: all 0.15s ease-out;
  }
}

a {
  cursor: pointer;
  
  @include themed() {
    text-shadow: 0px 0px 10px rgba(mix($primary, t('link-color')), 0.7), 0px 0px 20px rgba(mix($primary, t('link-color')), 0.15);
    color: t('link-color');
  }
}

input, button {
  font-family: 'Urbanist', 'Inter', sans-serif;
  border-radius: 8px;
  padding: 8px;
}

button.invisible {
  background: transparent;
  border: 0px;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent; 
}

::-webkit-scrollbar-thumb {
  background: rgba($primary, 0.4); 
}

::-webkit-scrollbar-thumb:hover {
  background: $primary; 
}