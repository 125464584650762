@import "../../layout";

.timeline {
    @include column(null, null, 16px);
    position: relative;
    width: 100%;

    &-line {
        position: absolute;
        top: 8px;
        left: 4px;
        bottom: 0px;
        z-index: 1;
        mask-image: linear-gradient(#000, transparent);

        @include themed() {
            border-left: 2px dashed rgba(t('color5'), 0.2);
        }
    }

    &-item {
        @include column(null, null, 16px);
        width: calc(100% - 32px);
        margin-left: 32px;

        &-header {
            position: relative;

            .timeline-point-wrapper {
                @include center;
                position: absolute;
                top: 4px;
                left: -33px;
                z-index: 2;
    
                .timeline-point {
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
    
                    @include themed() {
                        background-color: t('color4');
                        border: 2px solid rgba(t('link-color'), 0.75);
                    }
                }
            }

            &-title {
                @include row(null, null, 8px);

                .light {
                    @include themed() {
                        color: rgba(t('color5'), 0.4);
                    }
                }
            }
        }

        &-content {
            @include column(null, null, 12px);
        }
    }
}