@import "../../layout";

#security {
    @include column(center, center, 12px);

    .security-prompt {
        @include column(center, center);
        width: calc(100% - 32px);

        h2 {
            @include row(center, flex-start, 12px);
            width: 100%;
            font-size: 20px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        p {
            width: 100%;
            font-size: 13px;
            margin: 12px auto;
        }
    }

    .security-form-field {
        @include column(flex-start);

        .label {
            padding-left: 16px;
            font-size: 12px;
            padding-bottom: 6px;
        }
    }

    .password-requirements {
        width: calc(100% - 32px);

        & > div {
            @media only screen and (max-width: 385px) {
                font-size: 13px;
            }

            @media only screen and (max-width: 360px) {
                font-size: 12px;
            }

            @media only screen and (max-width: 340px) {
                font-size: 11px;
            }
        }
    }
}