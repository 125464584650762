@import "../../layout";

#transfer-send {
    @include column(center, center, 24px);
    width: calc(100% - 32px);
    padding: 0px 16px;

    #transfer-send-caption {
        @include center;
        width: calc(100% - 32px);
        font-size: 14px;
        text-align: center;

        .recipient {
            font-weight: 700;

            @include themed() {
                color: t('link-color');
            }
        }
    }

    #transfer-send-peers {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 24px 1fr;
        gap: 8px;
        margin: 0 auto;

        .transfer-send-peer {
            @include column(center, center, 4px);

            .transfer-send-peer-image {
                @include circle-avatar(48px);

                @include themed() {
                    background-color: rgba(t('color2'), 0.9);
                    color: t('color1');
                }
            }

            .transfer-send-peer-name {
                max-width: calc(50vw - 48px);
                height: 16px;
                font-size: 13px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                .unknown-sender,
                .unknown-recipient {
                    font-size: 11px;
                    opacity: 0.8;
                }
            }
        }

        & > svg {
            margin-bottom: 20px;
        }
    }

    .transfer-send-item {
        @include column(center, null, 12px);
        width: calc(100% - 1.6em);
        border-radius: 0.8em;
        padding: 0.8em;

        @include themed() {
            background-color: rgba(t('color3'), 0.9);
        }

        .transfer-send-item-session {
            @include row(center, flex-start, 12px);
            width: 100%;

            .transfer-send-item-image {
                @include avatar(80px);
            }

            .transfer-send-item-session-info {
                @include column(flex-start, space-between);
                height: 72px;

                .transfer-send-item-name {
                    width: calc(100vw - 124px - 1.6em);
                    font-size: 16px;
                    font-weight: 600;
                    white-space: nowrap;
                    text-overflow: clip;
                    mask-image: linear-gradient(90deg, #000 75%, transparent);
                }
    
                .transfer-send-item-date {
                    @include row(center, flex-start, 8px);
                    font-size: 13px;
                }
    
                .transfer-send-item-location {
                    @include row(center, flex-start, 8px);
                    font-size: 12px;   
                }

                .icon {
                    @include themed() {
                        color: t('link-color');
                    }
                }
            }
        }

        .transfer-send-item-batches {
            @include column(flex-start, null, 12px);
            width: 100%;
            font-size: 12px;
        }
    }

    #transfer-send-qr-code-holder {
        @include column(center, center, 16px);
        width: calc(100% - 32px);

        .qr-code {
            background-color: $primary;
            border-radius: 15px;
            padding: 15px;
            width: 200px;
            height: 200px;

            @include themed() {
                background: rgba(t('color3'), 0.9);
            }
        }

        button {
            height: 48px;
        }
    }

    button {
        @include row(center, center, 8px);
    }
}