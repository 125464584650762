@import "../../layout";

#signup {
    @include center;
    width: 100%;

    #signup-form {
        @include column(center, null, 12px);

        .terms {
            @include row(center, center, 8px);
            font-size: 10px;
            margin: 8px auto;

            .agree-on-terms-text {
                max-width: calc(100vw - 96px);
            }
        }

        #signup-signin-text {
            @include row(null, center, 5px);
            margin-top: 12px;
            font-size: 14px;

            a {
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .password-requirements {
        width: 100%;

        & > div {
            @media only screen and (max-width: 385px) {
                font-size: 13px;
            }

            @media only screen and (max-width: 360px) {
                font-size: 12px;
            }

            @media only screen and (max-width: 340px) {
                font-size: 11px;
            }
        }
    }
}