@import "../../layout";

#events {
    @include column(center, center, 8px);
    width: 100%;

    .events-search-container {
        margin-bottom: 8px;
    }

    .events-title {
        font-size: 18px;
        font-weight: 600;
        width: calc(100% - 32px);
        margin: 12px 16px;
    }

    .events-scrollable {
        @include row(center);
        position: relative;
        z-index: 4;
        width: 100%;
        white-space: nowrap;
        overflow-y: clip;

        & > *:first-child {
            margin-left: 16px !important;
        }

        & > *:last-child {
            margin-right: 16px !important;
        }

        &.single-event-featured {
            & > * {
                margin: 16px 32px;
            }
        }

        &.events-featured {
            gap: 32px;

            & > * {
                margin-top: 16px;
                margin-bottom: 16px;
            }

            & > *:first-child {
                margin-left: calc(50vw - (var(--featured-size) / 2)) !important;
            }

            & > *:last-child {
                margin-right: calc(50vw - (var(--featured-size) / 2)) !important;
            }
        }

        button {
            padding: 4px 16px;
            font-size: 13px;
            height: auto;
        }

        @media only screen and (min-width: 480px) {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            &::-webkit-scrollbar-button, &::-webkit-scrollbar-corner, &::-webkit-scrollbar-thumb:hover {
                background: $primary; 
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba($primary, 0.2); 
            }

            &::-webkit-scrollbar-track {
                background: transparent; 
            }
        }

        @media only screen and (max-width: 480px) {
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }

            &::-webkit-scrollbar-track, &::-webkit-scrollbar-button, &::-webkit-scrollbar-corner, &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:hover {
                background: transparent; 
            }
        }

        .form-button {
            margin: 0px 6px;
        }
    }

    .events-grid {
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        width: calc(100% - 32px);
        margin: 12px 16px 0px 16px;
    }

    .events-prompt {
        @include column(center);
        width: calc(100% - 32px);
        text-align: center;

        img {
            width: 60vw;
            margin-top: 16px;
        }

        h3 {
            margin-top: 40px;
            margin-bottom: 0px;
        }

        p {
            font-size: 14px;
            margin-top: 12px;
            margin-bottom: 0px;
        }
    }
}