@import "../../layout.scss";

#queue {
    @include column(center, center, 24px);
    width: 100%;
    min-height: calc(100vh - 264px);

    &-position {
        @include circle-avatar(128px);
        font-size: 22px;
        font-weight: bold;

        @include themed() {
            color: t('link-color');
            background-color:  rgba(t('color3'), 0.8);
        }
    }

    &-walking-guy {
        @include row(center, flex-start);
        width: 286px;
        height: 80px;
        overflow: hidden;
        filter: invert(50%) sepia(100%) saturate(2500%) hue-rotate(130deg) brightness(100%) contrast(100%);

        &-path {
            height: 1px;
            transition: width 1s ease-out;
        }

        &-image {
            margin-left: -50px;
            height: 80px;
        }
    }

    &-progressbar-container {
        background-color: rgba($primary, 0.4);
        border-radius: 5px;
        width: 286px;
        height: 10px;
        overflow: hidden;

        #queue-progressbar {
            background-color: $primary;
            border-radius: 5px;
            height: 10px;
            transition: width 1s ease-out;

            @include themed() {
                background: linear-gradient(-45deg, rgba(t('color2'), 0.15), rgba(t('color4'), 0.4)), $primary;
            }
        }
    }
}