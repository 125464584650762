@forward "./_colors";

@mixin flex($direction, $align-items: null, $justify-content: null, $gap: null) {
    display: flex;
    flex-direction: $direction;
    align-items: $align-items;
    justify-content: $justify-content;
    gap: $gap;
}

@mixin row($align-items: null, $justify-content: null, $gap: null) {
    @include flex(row, $align-items, $justify-content, $gap);
}

@mixin column($align-items: null, $justify-content: null, $gap: null) {
    @include flex(column, $align-items, $justify-content, $gap);
}

@mixin center {
    @include flex(null, center, center);
}

@mixin avatar($size: null, $border-radius: 0.5em, $object-fit: cover) {
    @if $size != null {
        @include center;
        width: $size;
        min-width: $size;
        max-width: $size;
        height: $size;
        min-height: $size;
        max-height: $size;
        border-radius: $border-radius;
        overflow: hidden;

        @if $object-fit != null {
            img {
                min-width: $size;
                min-height: $size;
                object-fit: $object-fit;
            }
        }
    } @else if $object-fit != null {
        background-position: center;
        background-repeat: no-repeat;
        background-size: $object-fit;
    }
}

@mixin circle-avatar($size: null, $object-fit: cover) {
    @if $size == null {
        @include avatar($size, 50%, $object-fit);
    } @else {
        @include avatar($size, calc($size / 2), $object-fit);
    }
}