@import "./layout";
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@100;400;600;700&display=swap');

$header-size: 64px;

#app {
    @include column(center);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;

    @include themed() {
        color: t('color5');
        background-image: linear-gradient(65deg, t('color3'), t('color4'));
    }

    #app-notifications {
        @include column(flex-end, null, 12px);
        position: fixed;
        right: 16px;
        bottom: 68px;
        z-index: 6;
        max-width: calc(100% - 32px);

        .app-notification {
            @include center;
            border-radius: 0.5em;
            padding: 8px 12px;
            box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
            backdrop-filter: blur(5px);

            @include themed() {
                color: t('color5');
                background-color: rgba(t('color3'), 0.6);
                border: 1px solid rgba(t('color5'), 0.4);
            }

            &.show {
                animation: appear 0.3s ease-out forwards;
            }

            &.hide {
                animation: disappear 0.3s ease-out forwards;
            }
        }
    }

    header#app-header {
        @include row(center, space-between);
		width: 100%;
		height: 80px;

        #app-header-greetings {
            width: calc(100% - 164px);
            margin: 28px 16px 0px 16px;
        }

		h1 {
			margin: 0px;
		}

        h2 {
            margin: 0px;
            margin-bottom: 2px;
            font-size: 16px;
            font-weight: 400;
        }

        h3 {
            margin: 0px;
            font-size: 18px;
            font-weight: 600;
        }

		#app-logo {
            position: relative;
            z-index: 5;
			margin: 28px 16px 0px 16px;
            width: 120px;
		}

        .app-header-corner {
            @include flex(null, center, center);
            margin-top: 28px;
            width: 52px;
            height: 52px;
            font-size: 18px;

            &.left {
                margin-left: 14px;

                #app-header-avatar {
                    @include circle-avatar(52px);
                    font-weight: bold;

                    @include themed() {
                        background-color: t('color3');
                        color: rgba(t('color5'), 0.4);
                    }
                }
            }

            &.right {
                margin-right: 14px;

                #app-header-notifications-container {
                    @include circle-avatar(52px, null);
                    @include themed() {
                        border: 1px rgba(t('color5'), 0.2) solid;
                    }
                }
            }
        }
	}

    #app-body {
        @include flex(null, flex-start, center);
        width: 100%;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-bottom: 48px;
    }

    #app-navbar {
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 6;
        width: 100%;
        height: 64px;

        &-container {
            @include center;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            mask-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7), #000);
            backdrop-filter: blur(1px);

            @include themed() {
                background-color: rgba(t('color4'), 0.4);
            }
        }

        &-content {
            position: absolute;
            bottom: 12px;
            width: calc(100% - 32px);
            height: 48px;
            border: 1px solid rgba($primary, 0.2);
            border-radius: 16px;
            background-color: rgba($primary, 0.2);
            backdrop-filter: blur(5px);

            @include themed() {
                background-image: linear-gradient(60deg, rgba(t('color3'), 0.4), rgba(t('color4'), 0.4));
            }

            ul {
                @include row(center, space-evenly);
                list-style-type: none;
                width: 100%;
                margin: 0;
                padding: 0;
                overflow: hidden;

                li {
                    @include column(center, center, 1px);
                    width: 48px;
                    height: 44px;
                    padding-top: 4px;
                    cursor: pointer;

                    &.active {
                        @include themed() {
                            color: t('link-color');
                        }
                    }

                    .app-navbar-icon {
                        height: 25px;
                        font-size: 18px;
                    }

                    .app-navbar-text {
                        font-size: 6px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.page-slider {
    width: 100%;
}

.page-slider-enter {
    & > .page {
        z-index: 5;
    }

    &.left > .page {
        transform: translate3d(100%, 0, 0);
    }

    &.right > .page {
        transform: translate3d(-100%, 0, 0);
    }

    &.page-slider-enter-active > .page {
        transform: translate3d(0, 0, 0);
        transition: all 800ms ease-in-out;
    }
}

.page-slider-exit {
    & > .page {
        z-index: 1;
    }

    &.left > .page {
        transform: translate3d(-100%, 0, 0);
    }

    &.right > .page {
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(15%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes disappear {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-15%);
    }
}