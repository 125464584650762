@import "../../../layout";

.form-input-checkbox {
    @include flex(null, center, space-between, 12px);
    font-size: 16px;
    font-weight: 600;

    .checkbox-container {

        display: block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
        }

        .checkedbox, .uncheckedbox {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            border: 2px solid;
            border-radius: 6px;
            border-color: $primary;
        }

        .uncheckedbox:hover {
            @include themed() {
                background-color: rgba(t('color5'), 0.2);
            }
        }

        .checkedbox {
            background-color: $primary;

            &:hover {
                background-color: rgba($primary, 0.9);
            }

            .checkmark {
                position: absolute;
                left: 5px;
                top: 2px;
                width: 3px;
                height: 8px;
                transform: rotate(45deg);

                @include themed() {
                    border: solid t('color5');
                    border-width: 0px 3px 3px 0px;
                }
            }
        }
    }
}

