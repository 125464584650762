@import "../../layout";

#transfer {
    @include column(center, center, 12px);
    width: 100%;

    .light {
        opacity: 0.6;
        font-size: 12px;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        width: calc(100% - 32px);
        margin: 12px 16px 0px 16px;

        &.transfer-type {
            @include row(center, space-between);
        }
    }

    #transfer-overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;
        min-width: 100%;
        min-height: 100vh;
        backdrop-filter: blur(2px);
        animation: overlay-animation 0.5s;

        @include themed() {
            background-color: rgba(t('color5'), 0.1);
        }
    }

    #transfer-peers {
        @include column(center, center, 12px);
        width: calc(100% - 32px);
        padding: 0px 16px 12px 16px;
        margin-bottom: 16px;

        @include themed() {
            border-bottom: 1px solid rgba(t('link-color'), 0.4);
        }

        .transfer-peer {
            @include row(center, space-between, 12px);
            width: 100%;

            &.bring-front {
                position: relative;
                z-index: 4;
            }

            .transfer-peer-definition {
                width: 48px;
                font-size: 14px;
            }

            .transfer-peer-container {
                @include row(center, null, 12px);
                width: calc(100% - 16px);
                height: 33px;
                border-radius: 0.5em;
                padding: 8px;

                @include themed() {
                    background-color: rgba(t('color3'), 0.6);
                }
            }

            .transfer-peer-image {
                @include circle-avatar(32px);

                @include themed() {
                    background-color: rgba(t('color2'), 0.6);
                }
            }

            .transfer-peer-details {
                @include column(flex-start, center, 4px);
                width: 100%;
                height: 100%;

                &.relative {
                    position: relative;
                }

                .transfer-peer-details-suggestions {
                    position: absolute;
                    top: 36px;
                    left: -51px;
                    width: calc(100% + 76px);
                    border-radius: 0.5em;
                    padding: 8px;

                    @include themed() {
                        background-color: rgba(t('color3'), 0.8);
                    }

                    .transfer-peer-details-suggestions-item {
                        @include row(center, null, 12px);
                        padding: 4px 0px;

                        .transfer-peer-details-suggestions-item-image {
                            @include circle-avatar(32px);

                            @include themed() {
                                background-color: rgba(t('color2'), 0.6);
                            }
                        }

                        .transfer-peer-details-suggestions-item-name {
                            font-size: 13px;
                        }
                    }
                }

                .transfer-peer-details-input {
                    position: relative;
                    width: 100%;
                    background-color: transparent;
                    border-radius: 0px;
                    border: 0px;
                    padding: 0px;
                    font-size: 13px;
                    outline: none;
                    transition: all 0.2 ease-out;

                    @include themed() {
                        color: t('color5');
                    }

                    &.subtitle {
                        font-size: 11px;
                        opacity: 0.6;
                    }
                }
            }

            .transfer-peer-name {
                font-size: 13px;
            }

            .transfer-peer-contact {
                font-size: 11px;
                opacity: 0.6;
            }

            .transfer-peer-search-user{
                @include center;
                color: $primary;
            }
        }
    }

    #transfer-object {
        @include row(center, null, 12px);
        width: calc(100% - 48px);
        border-radius: 0.5em;
        padding: 8px;

        @include themed() {
            background-color: rgba(t('color3'), 0.8);
        }

        #transfer-object-image {
            @include avatar(56px);
        }

        #transfer-object-details {
            @include column(flex-start, null, 8px);
            width: calc(100% - 128px);
            height: 100%;
        }

        #transfer-object-event-name,
        #transfer-object-event-batch {
            max-width: calc(100vw - 208px);
            width: 100%;
            white-space: nowrap;
            text-overflow: clip;
            mask-image: linear-gradient(90deg, #000 75%, transparent);
        }

        #transfer-object-event-name {
            font-size: 18px;
            font-weight: bold;
        }

        #transfer-object-event-batch {
            font-size: 13px;
            opacity: 0.6;
        }

        #transfer-object-quantity {
            @include row(center, flex-end, 12px);
            min-width: 80px;

            #transfer-object-quantity-display {
                font-size: 14px;
            }

            #transfer-object-quantity-selector {
                @include column(center, center);
            }
        }
    }

    #transfer-request {
        @include column(flex-start, null, 8px);
        width: calc(100% - 48px);
        border-radius: 0.5em;
        padding: 8px;

        @include themed() {
            background-color: rgba(t('color3'), 0.8);
        }

        &-price {
            width: 100%;
            font-size: 20px;

            input {
                border: 0px;
                background-color: transparent;
                font-size: 20px;
                outline: none;

                @include themed() {
                    color: t('color5');
                }
            }
        }

        &-allow-split {
            .checkbox-container {
                transform: scale(0.75);
                margin-bottom: 16px;
                padding-left: 16px;
            }

            .form-input-checkbox {
                font-size: 11px;
            }
        }

        &-no-parameters {
            width: 100%;
            font-size: 12px;
            text-align: center;
        }
    }

    #transfer-summary {
        @include column(center, center, 8px);
        width: calc(100% - 64px);
        border-radius: 0.5em;
        padding: 16px;

        @include themed() {
            background-color: rgba(t('color5'), 0.15);
        }

        .transfer-summary-item {
            @include row(center, space-between);
            width: 100%;
            font-size: 14px;
        }

        &-total {
            padding-top: 8px;
            font-weight: bold;

            @include themed() {
                border-top: 1px solid t('link-color');
            }
        }
    }

    #transfer-button-container {
        margin-top: 12px;
    }
}

@keyframes overlay-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}